import type { Organization } from "~/types/core/ecommerce/organization";

/**
 * Get extended organization information (NSR data) for the currently selected organization.
 *
 * Will only run if the user is logged in and has selected an organization, and will stay fresh for 1 hour, since organization data is not likely to change often.
 */
export function useSelectedOrganization() {
  const api = useCoreApi("organizations");

  const auth = useAuthorizeStore();

  const orgNo = computed(() => auth.selectedOrganization?.number);

  return useQuery({
    queryKey: ["organization", orgNo] as const,
    staleTime: 1000 * 60 * 60, // 1 hour
    enabled: !!unref(orgNo),
    queryFn: () => api.$fetch<Organization>(api.endpoint(unref(orgNo))),
  });
}
