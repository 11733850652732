import type { PurchaseHistoryItemDto } from "~/types/core/ecommerce/purchases";
import type { PaymentSource, ProductSource } from "~/types/core/common";

interface GetNewPurchaseHistoryParams {
  paymentSource?: PaymentSource;
  productSource?: ProductSource;
}

export function usePurchasesApi() {
  const api = useCoreApi("purchases");

  return {
    async getNewPurchaseHistory({
      paymentSource,
      productSource,
    }: GetNewPurchaseHistoryParams = {}) {
      const url = new URL(api.endpoint("new"));
      if (paymentSource) {
        url.searchParams.set("paymentsource", paymentSource);
      }
      if (productSource) {
        url.searchParams.set("productsource", productSource);
      }
      return await api.$fetch<PurchaseHistoryItemDto[]>(url.href);
    },

    async getOldPurchaseHistory(paymentSource?: "INVOICE" | "PREPAID") {
      const url = new URL(api.endpoint("old"));
      if (paymentSource) {
        url.searchParams.set("paymentsource", paymentSource);
      }
      return await api.$fetch<PurchaseHistoryItemDto[]>(url.href);
    },
  };
}
