import type {
  UserLicense,
  Site,
  ProductType,
  LicensedProduct,
  LicenseDetails,
} from "~/types/core/external/license";

interface GetLicensedProductsParams {
  preferredLanguage?: string;
  site?: Array<Site>;
  productType?: Array<ProductType>;
}

export function useLicensesApi() {
  const api = useCoreApi("frontend/licenses", (core) => core.external);

  return {
    async getLicenses() {
      return await api.$fetch<Array<UserLicense>>(api.rootUrl);
    },

    async getLicensedProducts(params: GetLicensedProductsParams = {}) {
      const url = new URL(api.endpoint("products"));
      for (const [key, value] of Object.entries(params)) {
        if (Array.isArray(value)) {
          value.forEach((v) => url.searchParams.append(key, v));
        } else {
          url.searchParams.append(key, value);
        }
      }

      return await api.$fetch<Array<LicensedProduct>>(url.href);
    },

    async getLicenseDetails(site?: Array<Site>) {
      const url = new URL(api.endpoint("details"));
      if (site) {
        site.forEach((s) => url.searchParams.append("site", s));
      }

      return await api.$fetch<Array<LicenseDetails>>(url.href);
    },
  };
}
