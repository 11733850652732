import { jwtDecode } from "jwt-decode";

export function isExpired(token: string | undefined) {
  if (!token) {
    return true;
  }

  const decoded = jwtDecode(token);
  const exp = Number(decoded.exp) * 1000;
  return exp < Date.now();
}
