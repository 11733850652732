import {
  AschehougOidcClient,
  RequestScopes,
} from "@aschehoug/aschehoug-oidc-client";
import type { PublicRuntimeConfig } from "nuxt/schema";

class EHandelOidcClient extends AschehougOidcClient {
  private readonly redirectUri: string;
  private readonly env: PublicRuntimeConfig;

  readonly tokenCookieName;
  readonly mediaTokenCookieName;

  constructor(env: PublicRuntimeConfig, requestUrl: URL) {
    const origin = requestUrl.origin;
    const redirectUri = origin + "/login/callback";
    const tokenCookieName = "ehandel_session";
    const mediaTokenCookieName = "ehandel_media_session";

    super({
      authority: env.urls.core.am,
      client_id: "ehandel_client",
      redirect_uri: origin + "/minside",
      redirect_feide_uri: redirectUri,
      // @ts-expect-error Erronous types in library
      scope: RequestScopes.openIdProfileEmail,
      post_logout_redirect_uri: origin,
      response_type: "code",
      metadata: {
        authorization_endpoint:
          env.urls.core.am + "/am/oauth2/realms/root/authorize",
      },
      base_uri_am: env.urls.core.am,
      selfService_api_url: env.urls.core.api,
      idCloudUrl: env.urls.core.id,
      external_api_url: env.urls.core.external,
      /* FIXME: Set these when we have upgraded to v3
      tokenCookieName,
      mediaTokenCookieName,
      cookieDomain: origin,
       */
    });

    this.redirectUri = redirectUri;
    this.env = env;
    this.tokenCookieName = tokenCookieName;
    this.mediaTokenCookieName = mediaTokenCookieName;
  }

  getVippsAuthUrl() {
    const url = new URL("/am/XUI/", this.env.urls.core.am);

    url.searchParams.set("service", "vipps_kunne_oidc_tree");
    url.searchParams.set("goto", this.redirectUri);
    url.searchParams.set("gotoOnFail", `${this.env.urls.core.id}/feil`);

    return url.toString();
  }
}

let client: EHandelOidcClient;

export default function useOidcClient() {
  if (!client) {
    const { public: env } = useRuntimeConfig();
    const url = useRequestURL();
    client = new EHandelOidcClient(env, url);
  }

  return client;
}
