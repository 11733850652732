import revive_payload_client_pO01Y7UURO from "/builds/aschehoug/aschehoug-utdanning/shop-app/frontend/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.5.5_eslint@8.57.1_ioredis@5.4.1_magicast@0.3_orikanfpc4vijucisc2gwqqlqq/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_HGaQCFBcem from "/builds/aschehoug/aschehoug-utdanning/shop-app/frontend/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.5.5_eslint@8.57.1_ioredis@5.4.1_magicast@0.3_orikanfpc4vijucisc2gwqqlqq/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_84pak3XKxi from "/builds/aschehoug/aschehoug-utdanning/shop-app/frontend/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.5.5_eslint@8.57.1_ioredis@5.4.1_magicast@0.3_orikanfpc4vijucisc2gwqqlqq/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_WLwfNlaaIS from "/builds/aschehoug/aschehoug-utdanning/shop-app/frontend/node_modules/.pnpm/nuxt-site-config@2.2.18_magicast@0.3.5_rollup@4.22.4_vite@5.4.6_@types+node@22.5.5_sass@1.79._tzp6ecttc7tygyt7vlem3kfy2m/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_UI2ofKKX8g from "/builds/aschehoug/aschehoug-utdanning/shop-app/frontend/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.5.5_eslint@8.57.1_ioredis@5.4.1_magicast@0.3_orikanfpc4vijucisc2gwqqlqq/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_3yPecACb9p from "/builds/aschehoug/aschehoug-utdanning/shop-app/frontend/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.5.5_eslint@8.57.1_ioredis@5.4.1_magicast@0.3_orikanfpc4vijucisc2gwqqlqq/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_Qk9Ua9o2Wk from "/builds/aschehoug/aschehoug-utdanning/shop-app/frontend/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.5.5_eslint@8.57.1_ioredis@5.4.1_magicast@0.3_orikanfpc4vijucisc2gwqqlqq/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_kG83CVJy8a from "/builds/aschehoug/aschehoug-utdanning/shop-app/frontend/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.5.5_eslint@8.57.1_ioredis@5.4.1_magicast@0.3_orikanfpc4vijucisc2gwqqlqq/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_fjSCCyhaDF from "/builds/aschehoug/aschehoug-utdanning/shop-app/frontend/node_modules/.pnpm/@pinia+nuxt@0.5.4_magicast@0.3.5_rollup@4.22.4_typescript@5.6.2_vue@3.5.6_typescript@5.6.2_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/builds/aschehoug/aschehoug-utdanning/shop-app/frontend/.nuxt/components.plugin.mjs";
import prefetch_client_kIb2uK9fTL from "/builds/aschehoug/aschehoug-utdanning/shop-app/frontend/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.5.5_eslint@8.57.1_ioredis@5.4.1_magicast@0.3_orikanfpc4vijucisc2gwqqlqq/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_Y9ryDfJXum from "/builds/aschehoug/aschehoug-utdanning/shop-app/frontend/node_modules/.pnpm/nuxt-graphql-client@0.2.36_@parcel+watcher@2.4.1_@types+node@22.5.5_graphql-tag@2.12.6_graphq_t6i4f45thbfjv3mbiep3whoh6i/node_modules/nuxt-graphql-client/dist/runtime/plugin.mjs";
import datadog_client_cfAAU11B0P from "/builds/aschehoug/aschehoug-utdanning/shop-app/frontend/plugins/datadog.client.ts";
import ks_toast_client_iF5eIzTVpP from "/builds/aschehoug/aschehoug-utdanning/shop-app/frontend/plugins/ks-toast.client.ts";
import error_handler_kEP5FliEXj from "/builds/aschehoug/aschehoug-utdanning/shop-app/frontend/plugins/error-handler.ts";
import vue_masonry_wall_13R6vu58Yn from "/builds/aschehoug/aschehoug-utdanning/shop-app/frontend/plugins/vue-masonry-wall.ts";
import vue_query_wrmMkNpEpe from "/builds/aschehoug/aschehoug-utdanning/shop-app/frontend/plugins/vue-query.ts";
export default [
  revive_payload_client_pO01Y7UURO,
  unhead_HGaQCFBcem,
  router_84pak3XKxi,
  _0_siteConfig_WLwfNlaaIS,
  payload_client_UI2ofKKX8g,
  navigation_repaint_client_3yPecACb9p,
  check_outdated_build_client_Qk9Ua9o2Wk,
  chunk_reload_client_kG83CVJy8a,
  plugin_vue3_fjSCCyhaDF,
  components_plugin_KR1HBZs4kY,
  prefetch_client_kIb2uK9fTL,
  plugin_Y9ryDfJXum,
  datadog_client_cfAAU11B0P,
  ks_toast_client_iF5eIzTVpP,
  error_handler_kEP5FliEXj,
  vue_masonry_wall_13R6vu58Yn,
  vue_query_wrmMkNpEpe
]