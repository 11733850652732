<script setup lang="ts">
import type { ArticleType, ArticleCard } from "~/types/articles";
import type { EventCard } from "~/types/events";
import type { Crystallize } from "~/crystallize/spec";

export type TagArticle = Pick<
  ArticleType | ArticleCard,
  "levels" | "subject" | "theme"
>;

const props = withDefaults(
  defineProps<
    {
      size?: "small" | "medium";
      article?: TagArticle;
      event?: EventCard;
    } & (
      | {
          article: TagArticle;
          event?: never;
        }
      | {
          article?: never;
          event: EventCard;
        }
    )
  >(),
  {
    article: undefined,
    event: undefined,
    size: "small",
  },
);

const EVENT_LABELS: Record<Crystallize.EventType, string> = {
  course: "kurs",
  themed: "fagkveld",
  webinar: "webinar",
  party: "fest",
  conferance: "konferanse",
};

const tags = computed(() => {
  if (props.article) {
    return getTagNamesFromValues(
      props.article.levels,
      props.article.subject,
      props.article.theme,
    );
  }

  if (props.event) {
    return [
      EVENT_LABELS[props.event.eventType],
      ...getTagNamesFromValues(props.event.levels),
    ];
  }

  return [];
});
</script>

<template>
  <div :class="`tag tag--${size}`">
    <span v-for="tag in tags" :key="tag">
      {{ tag }}
    </span>
  </div>
</template>

<style scoped lang="scss">
.tag {
  --gap: 0.4rem;
  display: flex;
  text-transform: uppercase;
  gap: var(--gap);
  align-items: center;
  margin-top: 0.5rem;
  flex-wrap: wrap;
  font-size: pixelsToRem(12);
  line-height: pixelsToRem(15);
  letter-spacing: pixelsToRem(1);

  span:not(:last-child):after {
    padding-left: var(--gap);
    content: "|";
  }
}

.tag--small {
  span {
    font-size: pixelsToRem(12);
  }
}

.tag--medium {
  span {
    font-size: pixelsToRem(18);
  }
}
</style>
