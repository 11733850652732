import { default as _91_46_46_46slug_93uXBQ4tBaj5Meta } from "/builds/aschehoug/aschehoug-utdanning/shop-app/frontend/pages/[...slug].vue?macro=true";
import { default as _91event_93e1j5VFIQvDMeta } from "/builds/aschehoug/aschehoug-utdanning/shop-app/frontend/pages/arrangementer/[event].vue?macro=true";
import { default as indexig2MT0V8WMMeta } from "/builds/aschehoug/aschehoug-utdanning/shop-app/frontend/pages/arrangementer/index.vue?macro=true";
import { default as _91slug_93R7jh0zsAfIMeta } from "/builds/aschehoug/aschehoug-utdanning/shop-app/frontend/pages/artikler/[slug].vue?macro=true";
import { default as indexLNymhKkXN1Meta } from "/builds/aschehoug/aschehoug-utdanning/shop-app/frontend/pages/artikler/index.vue?macro=true";
import { default as brukerinvolvering725yva6rl0Meta } from "/builds/aschehoug/aschehoug-utdanning/shop-app/frontend/pages/brukerinvolvering.vue?macro=true";
import { default as handlekurv93oRXbi1JyMeta } from "/builds/aschehoug/aschehoug-utdanning/shop-app/frontend/pages/handlekurv.vue?macro=true";
import { default as indexvjv2cPW50SMeta } from "/builds/aschehoug/aschehoug-utdanning/shop-app/frontend/pages/index.vue?macro=true";
import { default as informasjonskapslerg3LbYEynyfMeta } from "/builds/aschehoug/aschehoug-utdanning/shop-app/frontend/pages/informasjonskapsler.vue?macro=true";
import { default as kjopsbetingelserZPYDzywus0Meta } from "/builds/aschehoug/aschehoug-utdanning/shop-app/frontend/pages/kjopsbetingelser.vue?macro=true";
import { default as kundeservicejUzDGPfoeEMeta } from "/builds/aschehoug/aschehoug-utdanning/shop-app/frontend/pages/kundeservice.vue?macro=true";
import { default as bekreftmvnOLUDHNPMeta } from "/builds/aschehoug/aschehoug-utdanning/shop-app/frontend/pages/login/bekreft.vue?macro=true";
import { default as callback_46clientsO2FK3hLv7Meta } from "/builds/aschehoug/aschehoug-utdanning/shop-app/frontend/pages/login/callback.client.vue?macro=true";

let _createClientPage
async function createClientPage(loader) {
  _createClientPage ||= await import("/builds/aschehoug/aschehoug-utdanning/shop-app/frontend/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.5.5_eslint@8.57.1_ioredis@5.4.1_magicast@0.3_orikanfpc4vijucisc2gwqqlqq/node_modules/nuxt/dist/components/runtime/client-component").then(r => r.createClientPage)
  return _createClientPage(loader);
}
import { default as index58hClltSXhMeta } from "/builds/aschehoug/aschehoug-utdanning/shop-app/frontend/pages/login/index.vue?macro=true";
import { default as minside9mtBvqUQ5IMeta } from "/builds/aschehoug/aschehoug-utdanning/shop-app/frontend/pages/minside.vue?macro=true";
import { default as nyhetsbrevEsVjOi4ZDvMeta } from "/builds/aschehoug/aschehoug-utdanning/shop-app/frontend/pages/nyhetsbrev.vue?macro=true";
import { default as om_45aschehougtzP7WvNM46Meta } from "/builds/aschehoug/aschehoug-utdanning/shop-app/frontend/pages/om-aschehoug.vue?macro=true";
import { default as personvernXbY4pd31KbMeta } from "/builds/aschehoug/aschehoug-utdanning/shop-app/frontend/pages/personvern.vue?macro=true";
import { default as _91slug_93DB34NTvLFEMeta } from "/builds/aschehoug/aschehoug-utdanning/shop-app/frontend/pages/podkaster/[slug].vue?macro=true";
import { default as indextoC39bGiVFMeta } from "/builds/aschehoug/aschehoug-utdanning/shop-app/frontend/pages/podkaster/index.vue?macro=true";
import { default as _91slug_93vle7Xh5oRKMeta } from "/builds/aschehoug/aschehoug-utdanning/shop-app/frontend/pages/produktkatalog/[slug].vue?macro=true";
import { default as indexfr8J9F87yzMeta } from "/builds/aschehoug/aschehoug-utdanning/shop-app/frontend/pages/produktkatalog/index.vue?macro=true";
import { default as sok5M2y4LQJ8JMeta } from "/builds/aschehoug/aschehoug-utdanning/shop-app/frontend/pages/sok.vue?macro=true";
import { default as takkrGdsuhWhMnMeta } from "/builds/aschehoug/aschehoug-utdanning/shop-app/frontend/pages/takk.vue?macro=true";
export default [
  {
    name: "slug",
    path: "/:slug(.*)*",
    component: () => import("/builds/aschehoug/aschehoug-utdanning/shop-app/frontend/pages/[...slug].vue")
  },
  {
    name: "arrangementer-event",
    path: "/arrangementer/:event()",
    component: () => import("/builds/aschehoug/aschehoug-utdanning/shop-app/frontend/pages/arrangementer/[event].vue")
  },
  {
    name: "arrangementer",
    path: "/arrangementer",
    component: () => import("/builds/aschehoug/aschehoug-utdanning/shop-app/frontend/pages/arrangementer/index.vue")
  },
  {
    name: "artikler-slug",
    path: "/artikler/:slug()",
    component: () => import("/builds/aschehoug/aschehoug-utdanning/shop-app/frontend/pages/artikler/[slug].vue")
  },
  {
    name: "artikler",
    path: "/artikler",
    component: () => import("/builds/aschehoug/aschehoug-utdanning/shop-app/frontend/pages/artikler/index.vue")
  },
  {
    name: "brukerinvolvering",
    path: "/brukerinvolvering",
    component: () => import("/builds/aschehoug/aschehoug-utdanning/shop-app/frontend/pages/brukerinvolvering.vue")
  },
  {
    name: "handlekurv",
    path: "/handlekurv",
    meta: handlekurv93oRXbi1JyMeta || {},
    component: () => import("/builds/aschehoug/aschehoug-utdanning/shop-app/frontend/pages/handlekurv.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/builds/aschehoug/aschehoug-utdanning/shop-app/frontend/pages/index.vue")
  },
  {
    name: "informasjonskapsler",
    path: "/informasjonskapsler",
    component: () => import("/builds/aschehoug/aschehoug-utdanning/shop-app/frontend/pages/informasjonskapsler.vue")
  },
  {
    name: "kjopsbetingelser",
    path: "/kjopsbetingelser",
    component: () => import("/builds/aschehoug/aschehoug-utdanning/shop-app/frontend/pages/kjopsbetingelser.vue")
  },
  {
    name: "kundeservice",
    path: "/kundeservice",
    component: () => import("/builds/aschehoug/aschehoug-utdanning/shop-app/frontend/pages/kundeservice.vue")
  },
  {
    name: "login-bekreft",
    path: "/login/bekreft",
    meta: bekreftmvnOLUDHNPMeta || {},
    component: () => import("/builds/aschehoug/aschehoug-utdanning/shop-app/frontend/pages/login/bekreft.vue")
  },
  {
    name: "login-callback",
    path: "/login/callback",
    component: () => createClientPage(() => import("/builds/aschehoug/aschehoug-utdanning/shop-app/frontend/pages/login/callback.client.vue"))
  },
  {
    name: "login",
    path: "/login",
    meta: index58hClltSXhMeta || {},
    component: () => import("/builds/aschehoug/aschehoug-utdanning/shop-app/frontend/pages/login/index.vue")
  },
  {
    name: "minside",
    path: "/minside",
    meta: minside9mtBvqUQ5IMeta || {},
    component: () => import("/builds/aschehoug/aschehoug-utdanning/shop-app/frontend/pages/minside.vue")
  },
  {
    name: "nyhetsbrev",
    path: "/nyhetsbrev",
    component: () => import("/builds/aschehoug/aschehoug-utdanning/shop-app/frontend/pages/nyhetsbrev.vue")
  },
  {
    name: "om-aschehoug",
    path: "/om-aschehoug",
    component: () => import("/builds/aschehoug/aschehoug-utdanning/shop-app/frontend/pages/om-aschehoug.vue")
  },
  {
    name: "personvern",
    path: "/personvern",
    component: () => import("/builds/aschehoug/aschehoug-utdanning/shop-app/frontend/pages/personvern.vue")
  },
  {
    name: "podkaster-slug",
    path: "/podkaster/:slug()",
    component: () => import("/builds/aschehoug/aschehoug-utdanning/shop-app/frontend/pages/podkaster/[slug].vue")
  },
  {
    name: "podkaster",
    path: "/podkaster",
    component: () => import("/builds/aschehoug/aschehoug-utdanning/shop-app/frontend/pages/podkaster/index.vue")
  },
  {
    name: "produktkatalog-slug",
    path: "/produktkatalog/:slug()",
    component: () => import("/builds/aschehoug/aschehoug-utdanning/shop-app/frontend/pages/produktkatalog/[slug].vue")
  },
  {
    name: "produktkatalog",
    path: "/produktkatalog",
    component: () => import("/builds/aschehoug/aschehoug-utdanning/shop-app/frontend/pages/produktkatalog/index.vue")
  },
  {
    name: "sok",
    path: "/sok",
    component: () => import("/builds/aschehoug/aschehoug-utdanning/shop-app/frontend/pages/sok.vue")
  },
  {
    name: "takk",
    path: "/takk",
    meta: takkrGdsuhWhMnMeta || {},
    component: () => import("/builds/aschehoug/aschehoug-utdanning/shop-app/frontend/pages/takk.vue")
  }
]