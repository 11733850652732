import type {
  SubscriptionContractDto,
  SubscriptionContractEditRequest,
} from "~/types/core/ecommerce/subscriptions";

export function useSubscriptionsApi() {
  const api = useCoreApi("subscriptions");

  return {
    async getSubscriptions() {
      return await api.$fetch<Array<SubscriptionContractDto>>(api.rootUrl);
    },

    async editSubscription(id: string, data: SubscriptionContractEditRequest) {
      return await api.$fetch<SubscriptionContractDto>(api.endpoint(id), {
        method: "PATCH",
        body: JSON.stringify(data),
      });
    },
  };
}
