import type { ProductSource } from "~/types/core/common";
import type {
  CheckoutRequest,
  CheckoutResponse,
} from "~/types/core/ecommerce/checkout";

export function useCheckoutApi() {
  const api = useCoreApi("checkout");
  return {
    async initiateCheckoutSession(
      productSource: ProductSource,
      request: CheckoutRequest,
      redirectUrl?: string,
    ) {
      const url = new URL(api.rootUrl);
      url.searchParams.set("productSource", productSource);

      if (redirectUrl) {
        url.searchParams.set("redirectUrl", redirectUrl);
      }

      return await api.$fetch<CheckoutResponse>(url.href, {
        method: "POST",
        body: JSON.stringify(request),
      });
    },

    async getCheckoutSession(sessionId: string) {
      return await api.$fetch<CheckoutResponse>(api.endpoint(sessionId));
    },
  };
}
