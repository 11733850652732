import type { CartItemDto } from "~/types/core/ecommerce/cart";
import type { MetadataVariant } from "~/stores/CartStore";
import type { CoreUserSource } from "~/types/core/external/session";

export function createSimpleCartItemMapper(
  totStudents: number,
  source?: CoreUserSource,
) {
  return <TVariant extends string>(
    item: CartItemDto,
    variant: MetadataVariant<TVariant> | undefined,
  ): SimpleCartItem => {
    // TODO: Verify that variant subscription plan is correct and matches the cart item
    return {
      id: item.uuid,
      isbn: item.productId ?? variant?.isbn,
      amount: item.amount,
      isTrial: false,
      name: item.productName || variant?.title || variant?.name || undefined,
      description: variant?.description,
      cover: variant?.cover?.img,
      price: variant && calculatePrices(variant, totStudents, source),
      fixedAmount: variant?.fixedAmount ?? false,
      type: isSingleProductVariant(variant)
        ? "Digital bok"
        : "Digitalt læremiddel",
    };
  };
}
