import type { ProductSource } from "~/types/core/common";
import type {
  CartItemDto,
  CartDto,
  SubscriptionStartRequest,
} from "~/types/core/ecommerce/cart";

export function useCartApi() {
  const id = useState<UUID | undefined>("cartId", () => undefined);
  const api = useCoreApi("carts");

  return {
    async getActiveOrNewCart(productSource?: ProductSource) {
      const url = new URL(api.rootUrl);
      if (productSource) {
        url.searchParams.set("productSource", productSource);
      }

      const cart = await api.$fetch<CartDto>(url.href);
      if (cart) {
        id.value = cart?.uuid;
      }
      return cart ?? null;
    },

    async deleteCart() {
      return await api.$fetch<void>(api.endpoint(unref(id)), {
        method: "DELETE",
      });
    },

    async addItemToCart(item: Omit<CartItemDto, "uuid">) {
      return await api.$fetch<CartDto>(api.endpoint(unref(id), "item"), {
        method: "POST",
        body: JSON.stringify(item),
      });
    },

    async deleteItemFromCart(itemId: UUID) {
      return await api.$fetch<CartDto>(
        api.endpoint(unref(id), "item", itemId),
        {
          method: "DELETE",
        },
      );
    },

    async updateCartItem({ uuid: itemId, ...item }: CartItemDto) {
      return await api.$fetch<CartItemDto>(
        api.endpoint(unref(id), "item", itemId),
        {
          method: "PUT",
          body: JSON.stringify(item),
        },
      );
    },

    async startSubscriptions(request: SubscriptionStartRequest) {
      return await api.$fetch<void>(
        api.endpoint(unref(id), "start-subscriptions"),
        {
          method: "POST",
          body: JSON.stringify(request),
        },
      );
    },
  };
}
