<script setup lang="ts">
const props = defineProps<{
  to: string;
}>();

const handleClick = () => {
  if (useRoute().path === props.to) {
    useRouter().go(0);
  }
};
</script>

<template>
  <NuxtLink v-bind="$attrs" :to="to" @click="handleClick">
    <slot />
  </NuxtLink>
</template>
