import type { ImageWithAltType, ArticleType } from "./articles";
import type { Campaign } from "~/utils/campaign";
import type { Identifiable } from "~/types/document";
import type { ProductVariant, Product } from "~/types/product-catalogue";
import type { Crystallize } from "~/crystallize/spec";

export interface PacketProduct {
  title: string;
  ISBN?: string;
  info?: string;
  price?: number;
  included?: boolean;
}

export interface PacketVariant extends ProductVariant<"packet"> {
  customName?: string;
  included?: boolean;
  products?: PacketProduct[];
  school?: string;
  relatedSubscription?: string[];
}

export interface HeaderGraphic {
  url: string;
  position: string;
  color?: string;
}

export interface InfoCardCta {
  graphic: boolean;
  label?: string;
  url?: string;
  color?: string;
  isbn?: ISBN;
}

export interface InfoCard extends Identifiable {
  title: string;
  subTitle?: string;
  text: string;
  color: string;
  order: number;
  cta?: InfoCardCta;
  graphics?: HeaderGraphic[];
}

export const PACKET_THEMES = [
  "green-seagreen",
  "dark-red-pink",
  "blue-pink",
] as const satisfies ReadonlyArray<Crystallize.PacketColorTheme>;

export interface Packet extends Product<"packet", PacketVariant> {
  secondTitle?: string;
  infoCards?: InfoCard[];
  upcomingSubjects?: UpcomingSubject[];
  logo?: ImageWithAltType;
  videoAdv: VideoAdvType;
  campaign?: Campaign | null;
  relatedArticles: ArticleType[];
  theme: Crystallize.PacketColorTheme;
  productCta?: {
    title?: string;
    text?: string;
  };
}

export interface VideoAdvType {
  title?: string;
  url?: string;
}

export interface UpcomingSubject extends Identifiable {
  title?: string;
  description?: string;
  illustration?: ImageWithAltType;
}
