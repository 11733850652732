import type { SessionUserInfoDto } from "~/types/core/external/session";

export function useSessionApi() {
  const api = useCoreApi("frontend/session", (core) => core.external);

  return {
    async getSessionUserInfo() {
      return await api.$fetch<SessionUserInfoDto>(api.endpoint("user"));
    },

    async getSessionAttributes() {
      return await api.$fetch<Record<string, string>>(
        api.endpoint("attributes"),
      );
    },

    async setSessionAttributes(attributes: Record<string, string>) {
      return await api.$fetch<Record<string, string>>(
        api.endpoint("attributes"),
        {
          method: "PATCH",
          body: JSON.stringify(attributes),
        },
      );
    },
  };
}
