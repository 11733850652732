import type { UserMetadata } from "~/types/core/external/metadata";

export function useMetadataApi() {
  const api = useCoreApi("frontend/metadata", (core) => core.external);
  const auth = useAuthorizeStore();

  return {
    async getUserMetaData(key: string) {
      return await api.$fetch<UserMetadata>(api.endpoint(key), {
        method: "GET",
        handle404asNull: true,
      });
    },

    async createOrUpdateMetadata(key: string, value: string | null) {
      const userId = auth.userData?.userId;

      if (userId) {
        return await api.$fetch<UserMetadata>(api.endpoint(key), {
          method: "PUT",
          body: JSON.stringify({
            value,
            key,
            userId,
          }),
        });
      }
    },

    async deleteMetadata(key: string) {
      return await api.$fetch<UserMetadata>(api.endpoint(key), {
        method: "DELETE",
      });
    },
  };
}
